import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import firebase from "firebase/compat/app";
import React, { useMemo, useState } from "react";
import Loading from "../../components/Loading";
import LayoutMain, { useGutters } from "../../layouts/main";
import Palette from "../../styles/Palette";

import Moment from "moment";
import { extendMoment } from "moment-range";
import { RiArrowLeftLine, RiArrowRightLine } from "react-icons/ri";
import MyDropdownSelector from "../../components/Filters/MyDropdownSelector";
import TransportSearch from "../../components/Filters/TransportSearch";
import TransportDetailsCard from "../../components/TransportDetailsCard";
import TransportList from "../../components/TransportList";
import { dataDropdown } from "../../helpers/dataDropdown";
import useDataFromRef from "../../hooks/useDataFromRef";
import { useAuth } from "../../providers/Auth";
import {
  formatTransportDoc,
  Transports as RefTransports,
} from "../../services/Firebase";
import Colors from "../../styles/Colors";
import Global from "../../styles/Global";

const moment = extendMoment(Moment);

const CalendarWeekView = ({ actualDate, setActualDate, containerStyle }) => {
  const [dateRange, setDateRange] = useState(moment(actualDate).range("week"));

  const days = useMemo(() => Array.from(dateRange.by("day")), [dateRange]);

  // useEffect(() => {
  //   setActualDate(actualDate);
  // }, [days]);

  return (
    <div className={"containerRowCenter"} style={containerStyle}>
      <RiArrowLeftLine
        onClick={() =>
          setDateRange(moment(actualDate.subtract(1, "week")).range("week"))
        }
        size={32}
        color={Palette.blue}
        style={{ cursor: "pointer" }}
      />
      {days.map((day) => {
        const isSelected = actualDate.isSame(day, "day");
        return (
          <div
            onClick={() => {
              // const element = document.getElementById(day.format("YYYY-MM-DD"));
              // if (element) {
              //   const y =
              //     element.getBoundingClientRect().top +
              //     window.pageYOffset -
              //     325;
              //   window.scrollTo({ top: y, behavior: "smooth" });
              // }
              setActualDate(day);
            }}
            key={day.valueOf().toString()}
            className={"flex containerCenter"}
            style={{ cursor: "pointer" }}
          >
            <div style={{ fontSize: 16, marginBottom: 5 }}>
              {day.format("dddd DD MMM")}
            </div>
            <div
              style={{
                fontSize: 33,
                fontWeight: 800,
                backgroundColor: isSelected && Palette.salmon,
                color: isSelected && Palette.white,
                borderRadius: 999,
                padding: 5,
                width: 43,
                textAlign: "center",
              }}
            >
              {day.format("DD")}
            </div>
          </div>
        );
      })}
      <RiArrowRightLine
        onClick={() =>
          setDateRange(moment(actualDate.add(1, "week")).range("week"))
        }
        size={32}
        color={Palette.blue}
        style={{ cursor: "pointer" }}
      />
    </div>
  );
};

export default () => {
  const { dataTransportStatus } = dataDropdown;
  const [actualDate, setActualDate] = useState(moment());
  const [selected, setSelected] = useState(dataTransportStatus[0]);
  const [selectedFilter, setSelectedFilter] = useState(null);

  const auth = useAuth();
  const { uid } = firebase.auth().currentUser;

  const filters = useMemo(
    () =>
      RefTransports.where(
        auth.user.role === "HOSPITAL" ? "createdBy" : "regulatedBy",
        "==",
        uid
      )
        .where("startDate", ">=", actualDate.startOf("day").toDate())
        .where("startDate", "<=", actualDate.endOf("day").toDate())
        .orderBy("startDate"),
    [auth.user.role, actualDate]
  );

  const { data: defaultData, loading } = useDataFromRef({
    ref: filters,
    format: formatTransportDoc,
    listener: true,
    refreshArray: [actualDate],
  });

  const { data: bourseData } = useDataFromRef({
    ref: RefTransports.where("regulatedBy", "==", "BOURSE")
      .where("status", "==", "PENDING")
      .where("startDate", ">=", actualDate.startOf("day").toDate())
      .where("startDate", "<=", actualDate.endOf("day").toDate()),
    format: formatTransportDoc,
    listener: true,
    refreshArray: [actualDate],
  });

  const data = useMemo(() => {
    if (auth.user.role === "HOSPITAL") {
      return defaultData;
    }

    const tmp = [...defaultData, ...bourseData].sort((a, b) =>
      a.startDate < b.startDate ? -1 : 1
    );

    return tmp;
  }, [auth.user.role, defaultData, bourseData]);

  const transports = useMemo(() => {
    let tmp = data;
    // console.log("t", tmp);
    if (selectedFilter) {
      tmp = tmp.filter((t) => {
        if (selectedFilter.type === "PATIENT") {
          return selectedFilter.value.id === t.patient?.id;
        } else if (selectedFilter.type === "ADDRESS_NAME") {
          return (
            selectedFilter.value === t.from?.name ||
            selectedFilter.value === t.to?.name
          );
        } else if (selectedFilter.type === "ADDRESS_ADDRESS") {
          return (
            selectedFilter.value === t.from?.address ||
            selectedFilter.value === t.to?.address
          );
        }
      });
    }
    if (selected && selected?.value[0] !== "ALL") {
      tmp = tmp.filter((t) => t.status.includes(selected.value[0]));
    }
    // else {
    //   tmp = tmp.filter((t) => !(t.status === "REFUSED" && t.createdBy === uid));
    // }

    return tmp;
  }, [data, selectedFilter, selected]);

  const gutters = useGutters();

  return (
    <LayoutMain>
      <div
        style={{
          padding: 8,
          backgroundColor: Colors.main.background,
          zIndex: 1,
          paddingTop: 20,
          marginLeft: gutters - 60,
          marginRight: gutters - 60,
        }}
      >
        <div
          className={"containerRowSpaceBetween"}
          style={{ marginBottom: 30 }}
        >
          {/* <TransportStatusSelector
            containerStyle={{ marginRight: 20 }}
            {...{ selected, setSelected }}
          /> */}
          <MyDropdownSelector
            {...{
              selected,
              setSelected,
              data: dataTransportStatus,
            }}
          />

          {/*<DateRangeSelector*/}
          {/*  containerStyle={{ marginRight: 20 }}*/}
          {/*  {...{ startDate, setStartDate, endDate, setEndDate }}*/}
          {/*/>*/}
          <TransportSearch
            {...{ selectedFilter, setSelectedFilter, transports }}
          />
          {/*<PatientSelector {...{ selectedPatient, setSelectedPatient }} />*/}
        </div>
        <CalendarWeekView
          {...{ actualDate, setActualDate }}
          containerStyle={{ marginBottom: 0 }}
        />
      </div>
      <div
        className={"container"}
        style={{
          ...Global.containerItem,
          boxSizing: "border-box",
          marginTop: 20,
          minHeight: "calc(100vh - 340px)",
        }}
      >
        <TransportList
          {...{ transports, actualDate, setActualDate }}
          Component={TransportDetailsCard}
          // detectVisibility
          details
        />
      </div>
      {loading && <Loading />}
    </LayoutMain>
  );
};
